@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./buttons";
@import "./snacks";
@import "./variables";

.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;

  &.mso-no-fill {
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
  }
}

.mdc-notched-outline__notch {
  border-right: none;
}

::-ms-reveal {
  display: none;
}

html,
body,
app-root {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.tituloBoldRentokil {
  /* Titulo Bold Rentokil */
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 22px;
  color: #404D61;
}

.subtitleMediumRentokil {
  /* Subtitulo medium Rentokil */
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  color: #404D61;
}

.borderRoundedRentokil {
  border: 1px solid #404D61;
  border-radius: 10px 10px 10px 10px;
}

.width200 {
  min-width: 220px !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 5px grey;*/
  background-color: #F2F2F2;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border-radius: 10px;
}
