.mat-mdc-snack-bar-container {
  margin-top: 128px !important;

  &.check_circle {
    .mdc-snackbar__surface {
      background-color: var(--color-jungle-green) !important;
    }
  }

  &.emergency_home {
    .mdc-snackbar__surface {
      background-color: var(--color-amber) !important;
    }
  }

  &.error {
    .mdc-snackbar__surface {
      background-color: var(--color-red) !important;
    }
  }
}
