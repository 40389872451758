$rk-red: (
  50: #fff3f4,
  100: #fda9ae,
  200: #fb727a,
  300: #f92d39,
  400: #f90f1d,
  500: #e30613,
  600: #c50511,
  700: #a7040e,
  800: #8a040c,
  900: #6c0309,
  A100: #ffe9ea,
  A200: #ff838a,
  A400: #ff1d2a,
  A700: #ff0412,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$rk-french-blue: (
  50: #c9eaff,
  100: #7dcdff,
  200: #45b7ff,
  300: #009afc,
  400: #0088de,
  500: #0075bf,
  600: #0062a0,
  700: #005082,
  800: #003d63,
  900: #002a45,
  A100: #bfe6ff,
  A200: #59bfff,
  A400: #0094f2,
  A700: #0085d8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$rk-charcoal: (
  50: #ccd3de,
  100: #9eabbf,
  200: #7d8ea9,
  300: #586a86,
  400: #4c5c73,
  500: #404d61,
  600: #343e4f,
  700: #28303c,
  800: #1b212a,
  900: #0f1217,
  A100: #b8cbe8,
  A200: #6b92ce,
  A400: #446290,
  A700: #455875,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$rk-slate-gray: (
  50: #ffffff,
  100: #dbdde1,
  200: #bdc1c7,
  300: #969ca6,
  400: #868d98,
  500: #757d8a,
  600: #676e79,
  700: #595f69,
  800: #4b5058,
  900: #3d4148,
  A100: #ffffff,
  A200: #b9c7df,
  A400: #8193b1,
  A700: #7d899b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$rk-silver: (
  50: #ffffff,
  100: #ffffff,
  200: #f3f3f3,
  300: #d0d0d0,
  400: #c0c0c0,
  500: #b1b1b1,
  600: #a2a2a2,
  700: #929292,
  800: #838383,
  900: #747474,
  A100: #ffffff,
  A200: #fefdfd,
  A400: #d2c3c3,
  A700: #c1baba,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$rk-anti-flash-white: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: #ffffff,
  400: #ffffff,
  500: #f1f2f6,
  600: #dee1ea,
  700: #cccfde,
  800: #b9bed2,
  900: #a7adc6,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$rk-seasalt: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: #ffffff,
  400: #ffffff,
  500: #f7f9fb,
  600: #e3eaf1,
  700: #cedae7,
  800: #bacbdc,
  900: #a5bcd2,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$rk-jungle-green: (
  50: #fcfefd,
  100: #c7e6db,
  200: #a0d5c2,
  300: #6fbfa2,
  400: #5ab694,
  500: #4aa785,
  600: #419274,
  700: #377d63,
  800: #2e6752,
  900: #245241,
  A100: #f3fdf9,
  A200: #9dedd0,
  A400: #58cca2,
  A700: #50ba93,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$rk-picton-blue: (
  50: #ffffff,
  100: #eff7fb,
  200: #c2dfef,
  300: #8ac1e1,
  400: #71b5da,
  500: #59a8d4,
  600: #419bce,
  700: #318cbe,
  800: #2b7aa6,
  900: #25688e,
  A100: #ffffff,
  A200: #caeafc,
  A400: #76c1ea,
  A700: #69b4de,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$rk-tropical-indigo: (
  50: #ffffff,
  100: #ffffff,
  200: #eeeef9,
  300: #b8b9e8,
  400: #a1a3e0,
  500: #8a8cd9,
  600: #7375d1,
  700: #5c5fca,
  800: #4548c2,
  900: #393cb0,
  A100: #ffffff,
  A200: #fdfdff,
  A400: #a9aaed,
  A700: #9a9ce2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$rk-amber: (
  50: #ffffff,
  100: #fef5dd,
  200: #fde5a6,
  300: #fcd160,
  400: #fcc842,
  500: #fbbf24,
  600: #fab606,
  700: #dea104,
  800: #c08b04,
  900: #a27503,
  A100: #ffffff,
  A200: #ffebb9,
  A400: #ffcf53,
  A700: #ffc839,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-rkblueprimary: (
  50 : #e0eef7,
  100 : #b3d6ec,
  200 : #80badf,
  300 : #4d9ed2,
  400 : #268ac9,
  500 : #0075bf,
  600 : #006db9,
  700 : #0062b1,
  800 : #0058a9,
  900 : #00459b,
  A100 : #c6dbff,
  A200 : #93bbff,
  A400 : #609bff,
  A700 : #478bff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


